import React, { ReactNode } from "react";

import { Space } from "antd";
import cn from "classnames";

import { RadioEmptySVG, RatioSVG, RadioDisabledSVG, TickSquareEmptySVG, TickSquareSVG } from "assets/icons";

import styles from "./SelectionIndicator.module.scss";

export type SelectionType = "radio" | "checkbox";

export type SelectionIndicatorProps = {
  type: SelectionType;
  checked: boolean;
  disabled?: boolean;
  label?: ReactNode;
  className?: string;
  spaceSize?: number;
  dataCy?: string;
  onClick?: () => void;
};

const getIcon = (type: SelectionIndicatorProps["type"], checked: boolean, disabled: boolean) => {
  switch (true) {
    case type === "checkbox" && checked:
      return <TickSquareSVG />;
    case type === "checkbox" && !checked:
      return <TickSquareEmptySVG />;
    case type === "radio" && checked:
      return disabled ? <RadioDisabledSVG /> : <RatioSVG />;
    case type === "radio" && !checked:
      return <RadioEmptySVG />;
    default:
      return null;
  }
};

export const SelectionIndicator = ({
  type,
  checked,
  disabled,
  label,
  className,
  spaceSize = 10,
  dataCy,
  onClick,
}: SelectionIndicatorProps) => {
  const icon = getIcon(type, checked, disabled as boolean);
  return (
    <Space
      data-cy={dataCy}
      className={cn(styles.selectionIndicator, className, { [styles.checked]: checked })}
      size={spaceSize}
      align='center'
      onClick={onClick}
    >
      {icon}
      {label}
    </Space>
  );
};
