import React from "react";

import { Tier4SVG } from "assets/icons";
import { Tooltip } from "components/Tooltip";
import { Tier4BadgeOptionTooltip } from "components/TooltipsContent";
import {
  ANNUAL_INCOME as ANNUAL_INCOME_ENUM,
  FREQUENCY_LEVEL as FREQUENCY_LEVEL_ENUM,
  INVESTMENT_LEVEL as INVESTMENT_LEVEL_ENUM,
  NET_WORTH as NET_WORTH_ENUM,
  SOURCE_OF_FUNDS_IND as SOURCE_OF_FUNDS_IND_ENUM,
} from "constants/virtualAssets";
import { capitalize } from "utils/textFormat";

import { EMPLOYMENT_STATUS as EMPLOYMENT_STATUS_ENUM } from "./kyc";
import { SOURCE_OF_WEALTH_IND as SOURCE_OF_WEALTH_IND_ENUM } from "./virtualAssets";

export const BOOLEAN_ANSWER = {
  yes: "yes",
  no: "no",
} as const;

export const BOOLEAN_OPTIONS = [
  { value: BOOLEAN_ANSWER.yes, option: capitalize(BOOLEAN_ANSWER.yes) },
  { value: BOOLEAN_ANSWER.no, option: capitalize(BOOLEAN_ANSWER.no) },
] as const;

export const DOCUMENT_STATUS = {
  active: "active",
  expiring: "expiring",
  expired: "expired",
} as const;

export const DOCUMENT_OF_PROCESS = {
  verification: "verification",
  certification: "certification",
  extraEdd: "extra_edd",
} as const;

export const DOCUMENT_TYPE = {
  proofOfIdentityDocument: "proof_of_identity_document",
  proofOfAddress: "proof_of_address",
  proofOfAddressEn: "proof_of_address_en",
  poaSelfie: "poa_selfie",
  poaSelfieEn: "poa_selfie_en",
  poaAccountScreenshot: "poa_account_screenshot",
  poaAccountScreenshotEn: "poa_account_screenshot_en",
  poaCertified: "poa_certified",
  poaCertifiedEn: "poa_certified_en",
  extraEdd: "extra_edd",
  certificationPoa: "certification_poa",
  certificationPoaEn: "certification_poa_en",
  certificationPoaAccountScreenshot: "certification_poa_account_screenshot",
  certificationPoaSelfie: "certification_poa_selfie",
} as const;

export const EMPLOYMENT_STATUS = [
  { label: "Employed", value: EMPLOYMENT_STATUS_ENUM.employed },
  { label: "Self Employed", value: EMPLOYMENT_STATUS_ENUM.selfEmployed },
  { label: "Student", value: EMPLOYMENT_STATUS_ENUM.student },
  { label: "Business Owner", value: EMPLOYMENT_STATUS_ENUM.businessOwner },
  { label: "Unemployed/Retired/Homemaker", value: EMPLOYMENT_STATUS_ENUM.unemployed },
] as const;

export const SOURCE_OF_WEALTH_IND = [
  { option: "Employment Income", value: SOURCE_OF_WEALTH_IND_ENUM.employment_income },
  { option: "Savings/Deposits", value: SOURCE_OF_WEALTH_IND_ENUM.savings },
  { option: "Gift/Inheritance", value: SOURCE_OF_WEALTH_IND_ENUM.gift },
  { option: "Company profits/Dividends", value: SOURCE_OF_WEALTH_IND_ENUM.company_profits },
  { option: "Investment income", value: SOURCE_OF_WEALTH_IND_ENUM.investment_income },
  { option: "Profit from maturing/sold investments", value: SOURCE_OF_WEALTH_IND_ENUM.sold_investment },
  { option: "Profit from Property sale", value: SOURCE_OF_WEALTH_IND_ENUM.property_sale },
  { option: "Profit from Sale of Company", value: SOURCE_OF_WEALTH_IND_ENUM.company_sale },
  { option: "Compensation payment", value: SOURCE_OF_WEALTH_IND_ENUM.compensation },
  { option: "Loans", value: SOURCE_OF_WEALTH_IND_ENUM.loans },
  { option: "Lottery/Gambling winnings", value: SOURCE_OF_WEALTH_IND_ENUM.lottery },
  { option: "Other", value: SOURCE_OF_WEALTH_IND_ENUM.other },
] as const;

export const INVESTMENT_LEVEL = [
  { option: "$1,000-$10,000", value: INVESTMENT_LEVEL_ENUM.to10k },
  { option: "$10,001-$50,000", value: INVESTMENT_LEVEL_ENUM.to50k },
  { option: "$50,001-$100,000", value: INVESTMENT_LEVEL_ENUM.to100k },
  { option: "$100,001+", value: INVESTMENT_LEVEL_ENUM.over100k },
] as const;

export const FREQUENCY_LEVEL = [
  { option: "1-5", value: FREQUENCY_LEVEL_ENUM.to5 },
  { option: "6-10", value: FREQUENCY_LEVEL_ENUM.to10 },
  { option: "11-25", value: FREQUENCY_LEVEL_ENUM.to25 },
  { option: "26-50", value: FREQUENCY_LEVEL_ENUM.to50 },
  { option: "51+", value: FREQUENCY_LEVEL_ENUM.over50 },
] as const;

export const NET_WORTH = [
  { option: "< $75,000", value: NET_WORTH_ENUM.below75k },
  { option: "$75,000 - $100,000", value: NET_WORTH_ENUM.to100k },
  { option: "$100,000 - $200,000", value: NET_WORTH_ENUM.to200k },
  { option: "$200,000 - $500,000", value: NET_WORTH_ENUM.to500k },
  { option: "$500,000 - $1,000,000", value: NET_WORTH_ENUM.to1m },
  {
    option: "$1,000,000 - $5,000,000",
    value: NET_WORTH_ENUM.to5m,
    rightSide: (
      <Tooltip title={<Tier4BadgeOptionTooltip />}>
        <Tier4SVG />
      </Tooltip>
    ),
  },
  {
    option: "> $5,000,000",
    value: NET_WORTH_ENUM.over5m,
    rightSide: (
      <Tooltip title={<Tier4BadgeOptionTooltip />}>
        <Tier4SVG />
      </Tooltip>
    ),
  },
] as const;

export const SOURCE_OF_FUNDS_IND = [
  { option: "Income from salary and bonuses from employment", value: SOURCE_OF_FUNDS_IND_ENUM.employment_income },
  { option: "Inheritance", value: SOURCE_OF_FUNDS_IND_ENUM.inheritance },
  {
    option:
      "Investment through a regulatory intermediary (e.g stocks, shares, bonds, dividends, interest, capital gains, company sale, sale of interest in company)",
    value: SOURCE_OF_FUNDS_IND_ENUM.regulatory_intermediary_investment,
  },
  {
    option:
      "Another Passive income - earnings from a rental property, income from royalties/income from patents/franchises",
    value: SOURCE_OF_FUNDS_IND_ENUM.passive_income,
  },
  { option: "Retirement Income", value: SOURCE_OF_FUNDS_IND_ENUM.retirement_income },
  { option: "Savings", value: SOURCE_OF_FUNDS_IND_ENUM.savings },
  { option: "Other", value: SOURCE_OF_FUNDS_IND_ENUM.other },
] as const;

export const ANNUAL_INCOME = [
  { option: "< $1,000", value: ANNUAL_INCOME_ENUM.below1k },
  { option: "$1,000 - $2,500", value: ANNUAL_INCOME_ENUM.to2k }, // TODO verify, on frontend says 2.500
  { option: "$2,500 - $5,000", value: ANNUAL_INCOME_ENUM.to5k },
  { option: "$5,000 - $10,000", value: ANNUAL_INCOME_ENUM.to10k },
  { option: "$10,000 - $20,000", value: ANNUAL_INCOME_ENUM.to20k },
  { option: "$20,000 - $50,000", value: ANNUAL_INCOME_ENUM.to50k },
  { option: "$50,000 - $100,000", value: ANNUAL_INCOME_ENUM.to100k },
  { option: "$100,000 - $250,000", value: ANNUAL_INCOME_ENUM.to250k },
  { option: "> $250,000", value: ANNUAL_INCOME_ENUM.over250k },
] as const;
