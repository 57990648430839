import React, { useState } from "react";

import { Space } from "antd";
import { useFormik } from "formik";
import { clone, filter, not, omit, pickAll, pipe, propEq } from "ramda";

import { Alert } from "components/Alert";
import { BackButton } from "components/BackButton";
import { Question } from "components/Question";
import { Select } from "components/Select";
import { SubmitButton } from "components/SubmitButton";
import { TextInput } from "components/TextInput";
import { BOOLEAN_ANSWER, BOOLEAN_OPTIONS } from "constants/formData";
import { useFormikActions, useKycData, useMutateKyc, usePostcodeSample, useStepsProgress } from "hooks";
import { BooleanAnswer } from "types/formData";
import { KycInd } from "types/kyc";

import { appsignal } from "../../../../../appsignal";
import { useCountriesContext } from "../../../../../contexts/countriesCtx";
import { isSanctionedCountryQuestionsRequired } from "../../helpers";

import { validationSchema } from "./validationSchema";

import styles from "./AddressDetails.module.scss";

const getSecondaryNationalities = ({
  hasOtherNationality1,
  otherNationality1,
  hasOtherNationality2,
  otherNationality2,
}: {
  hasOtherNationality1: BooleanAnswer;
  otherNationality1: string | null;
  hasOtherNationality2: BooleanAnswer;
  otherNationality2: string | null;
}) => {
  const secondaryNationalities: KycInd["secondaryNationalities"] = [
    { has: hasOtherNationality1, country: otherNationality1 },
  ];
  if (hasOtherNationality1 === "yes") {
    secondaryNationalities.push({ has: hasOtherNationality2, country: otherNationality2 });
  }
  return secondaryNationalities;
};
export const AddressDetails = () => {
  const { data } = useKycData();
  const { countriesOptions = [] } = useCountriesContext();
  const [selectedCountry, setSelectedCountry] = useState(data?.current.addressCountry);
  const postcodePlaceholder = usePostcodeSample(selectedCountry ?? "");
  if (!data) return null;

  const updateKyc = useMutateKyc();
  const { goToNextScreen, goToPreviousScreen, setScreen } = useStepsProgress();

  const initialValues = pickAll(
    [
      "addressCountry",
      "addressLine1",
      "addressLine2",
      "city",
      "state",
      "postcode",
      "placeOfBirth",
      "nationality",
      "nationalityLocked",
    ],
    clone(data.last as KycInd)
  );

  const formik = useFormik({
    initialValues: {
      ...initialValues,
      hasOtherNationality1: data.last.secondaryNationalities?.[0]?.has,
      otherNationality1: data.last.secondaryNationalities?.[0]?.country || null,
      hasOtherNationality2: data.last.secondaryNationalities?.[1]?.has,
      otherNationality2: data.last.secondaryNationalities?.[1]?.country || null,
    },
    onSubmit: (data, { setSubmitting }) => {
      const secondaryNationalities = getSecondaryNationalities(data as any);
      const dataWithoutOtherNationalities = omit(
        ["hasOtherNationality1", "otherNationality1", "hasOtherNationality2", "otherNationality2"],
        data
      );

      const isSanctionedCountryStepRequired = isSanctionedCountryQuestionsRequired([
        data.addressCountry,
        data.nationality,
      ]);

      updateKyc
        .mutateAsync({
          ...dataWithoutOtherNationalities,
          secondaryNationalities,
          currentStep: isSanctionedCountryStepRequired ? 4 : 5,
        })
        .then(() => {
          setSubmitting(false);
          if (isSanctionedCountryStepRequired) {
            setScreen(4);
          } else {
            setScreen(5);
          }
        })
        .catch((err) => {
          setSubmitting(false);
          appsignal.sendError(err);
        });
    },
    validationSchema,
    validateOnMount: true,
  });

  const { handleChange, getError } = useFormikActions(formik);

  const handleOtherNationalityChange = (index: number) => (value: BooleanAnswer) => {
    if (index === 1 && value === "no") {
      formik.setValues((prev) => ({
        ...prev,
        otherNationality1: null,
        hasOtherNationality2: undefined,
        otherNationality2: null,
      }));
    } else if (index === 2 && value === "no") {
      formik.setValues((prev) => ({
        ...prev,
        otherNationality2: null,
      }));
    }
    handleChange(`hasOtherNationality${index}` as any)(value);
  };

  const nationalityAlertContent = () => {
    return (
      <span>
        If there are any mistakes or changes to your nationality or place of birth, please contact support{" "}
        <a target='_blank' href={process.env.REACT_APP_CORE_SUPPORT} rel='noreferrer'>
          here
        </a>
        . To request an update, you will need to provide a valid reason along with supporting documents, such as a
        government-issued correction letter, a court order, or an updated passport. Once reviewed and approved, our KYC
        team will update your profile accordingly.
      </span>
    );
  };

  return (
    <div className={styles.addressDetails}>
      <h2 data-cy='address-details-title'>Address details</h2>
      <p>
        The address your provide us with will be used to not only fulfill anti-money laundering obligations, but will
        also be shared with our banking and payment providers for processing.
      </p>
      <Alert
        type='warning'
        description='The address should match the address registered on your bank account and/or payment cards.'
        greyOut={true}
      />
      <Space direction='vertical' size={30} className={styles.formSpace}>
        <Select
          data-cy='addressCountry-field'
          options={[...countriesOptions]}
          value={formik.values.addressCountry}
          onChange={(value) => {
            setSelectedCountry(value);
            handleChange("addressCountry")(value);
          }}
          error={getError("addressCountry")}
          label='Country of residence'
          showSearch
          optionFilterProp='label'
          disabled={formik.isSubmitting}
        />
        <TextInput
          data-cy='address1-field'
          label='Address'
          value={formik.values.addressLine1}
          onTextChange={handleChange("addressLine1")}
          error={getError("addressLine1")}
          name='address1'
          disabled={formik.isSubmitting}
        />
        <TextInput
          data-cy='address2-field'
          label='Address'
          value={formik.values.addressLine2 || ""}
          onTextChange={handleChange("addressLine2")}
          error={getError("addressLine2")}
          name='address2'
          optional
          disabled={formik.isSubmitting}
        />
        <TextInput
          data-cy='city-field'
          label='City/Town'
          value={formik.values.city}
          onTextChange={handleChange("city")}
          error={getError("city")}
          name='city'
          disabled={formik.isSubmitting}
        />
        <TextInput
          data-cy='state-field'
          label='State/Province/Region'
          value={formik.values.state}
          onTextChange={handleChange("state")}
          error={getError("state")}
          name='state'
          disabled={formik.isSubmitting}
        />
        <TextInput
          data-cy='postalCode-field'
          label='Postcode/Zip'
          value={formik.values.postcode}
          onTextChange={handleChange("postcode")}
          error={getError("postcode")}
          name='postal-code'
          disabled={formik.isSubmitting}
          placeholder={postcodePlaceholder}
        />
        <Select
          data-cy='placeOfBirth-field'
          label='Place of birth'
          value={formik.values.placeOfBirth}
          options={[...countriesOptions]}
          onChange={handleChange("placeOfBirth")}
          error={getError("placeOfBirth")}
          showSearch
          optionFilterProp='label'
          disabled={formik.isSubmitting || initialValues.nationalityLocked}
        />
        <Select
          data-cy='nationality-field'
          label='Nationality'
          value={formik.values.nationality}
          options={[...countriesOptions]}
          onChange={handleChange("nationality")}
          error={getError("nationality")}
          showSearch
          optionFilterProp='label'
          disabled={formik.isSubmitting || initialValues.nationalityLocked}
        />
        <Question
          dataKeyPrefix='hasOtherNationality1'
          options={[...BOOLEAN_OPTIONS]}
          value={formik.values.hasOtherNationality1 || ""}
          onClick={handleOtherNationalityChange(1) as any}
          error={getError("hasOtherNationality1")}
          answerType='radio'
          label='Do you have another nationality?'
          disabled={formik.isSubmitting || initialValues.nationalityLocked}
        />
        {formik.values.hasOtherNationality1 === BOOLEAN_ANSWER.yes ? (
          <>
            <Select
              data-cy='otherNationality1-field'
              label='Another nationality'
              value={formik.values.otherNationality1}
              onChange={handleChange("otherNationality1")}
              error={getError("otherNationality1")}
              options={filter(pipe(propEq("value", formik.values.nationality), not), [...countriesOptions])}
              showSearch
              optionFilterProp='label'
              disabled={formik.isSubmitting || initialValues.nationalityLocked}
            />
            <Question
              data-cy='hasOtherNationality2-field'
              options={[...BOOLEAN_OPTIONS]}
              value={formik.values.hasOtherNationality2 || ""}
              onClick={handleOtherNationalityChange(2) as any}
              error={getError("hasOtherNationality2")}
              answerType='radio'
              label='Do you have another nationality?'
              disabled={formik.isSubmitting || initialValues.nationalityLocked}
            />
          </>
        ) : null}
        {formik.values.hasOtherNationality2 === BOOLEAN_ANSWER.yes ? (
          <Select
            data-cy='otherNationality2-field'
            label='Another nationality'
            value={formik.values.otherNationality2}
            error={getError("otherNationality2")}
            onChange={handleChange("otherNationality2")}
            options={filter(
              ({ value }) => ![formik.values.nationality, formik.values.otherNationality1].includes(value),
              [...countriesOptions]
            )}
            showSearch
            optionFilterProp='label'
            disabled={formik.isSubmitting || initialValues.nationalityLocked}
          />
        ) : null}
        {initialValues.nationalityLocked ? (
          <Alert type='warning' description={nationalityAlertContent()} greyOut={true} />
        ) : null}
        <SubmitButton
          onClick={formik.submitForm}
          disabled={!formik.isValid && formik.submitCount !== 0}
          loading={formik.isSubmitting}
        >
          Continue
        </SubmitButton>
      </Space>
      <BackButton
        onClick={() => {
          goToPreviousScreen(() => {
            updateKyc.mutateAsync({ currentStep: 2 });
          });
        }}
      />
    </div>
  );
};
