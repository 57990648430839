import React from "react";

import styles from "./Tier4BadgeOptionTooltip.module.scss";

export const Tier4BadgeOptionTooltip = () => {
  return (
    <div className={styles.tier4BadgeOptionTooltip}>
      <b>Unlock Tier 4 Status</b>
      <div>
        Selecting this option means you may qualify for Tier 4 status. During the Tier 4 session, you’ll need to provide
        proof of net worth exceeding $1M, verified by a third party.
      </div>
    </div>
  );
};
