import React from "react";

import { Modal } from "antd";
import { useNavigate } from "react-router-dom";

import { Button } from "components/Button";
import { Question } from "components/Question";
import { NET_WORTH } from "constants/formData";
import { VIRTUAL_ASSETS_PATH } from "constants/pages";
import { NET_WORTH as NET_WORTH_ENUM } from "constants/virtualAssets";

import styles from "./HowToUpgradeToTier4Modal.module.scss";

type HowToUpgradeToTier4ModalProps = {
  open: boolean;
  handleClose: () => void;
};

export const HowToUpgradeToTier4Modal = ({ open, handleClose }: HowToUpgradeToTier4ModalProps) => {
  const navigate = useNavigate();
  const closeModal = () => {
    handleClose();
  };

  const handleClickUpdateButton = () => {
    navigate(VIRTUAL_ASSETS_PATH);
  };

  return (
    <Modal
      open={open}
      width={660}
      centered
      closable
      className={styles.HowToUpgradeToTier4Modal}
      onCancel={closeModal}
      footer={
        <div className={styles.modalFooter}>
          <Button onClick={handleClickUpdateButton}>Update Tier 1 now</Button>
          <a onClick={closeModal}>Cancel</a>
        </div>
      }
    >
      <div className={styles.modalContent}>
        <h5 className={styles.modalTitle}>How to upgrade to Tier 4</h5>
        <p>
          You may qualify for Tier 4! If your net worth has increased above $1M, update it by completing a new Tier 1
          session to unlock Tier 4.{" "}
        </p>

        <div className={styles.modalExplanation}>
          <Question
            dataKeyPrefix='netWorth'
            options={[...NET_WORTH]}
            answerType='radio'
            label='Net worth (excluding primary residence, and money raised as loans secured on that property)'
            value={[NET_WORTH_ENUM.to5m, NET_WORTH_ENUM.over5m]}
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            onClick={() => {}}
          />
        </div>
      </div>
    </Modal>
  );
};
