import React, { ReactNode } from "react";

import { Space } from "antd";
import cn from "classnames";

import { AnswerOption, SelectionOption } from "components/AnswerOption";
import { InputWrapper, InputWrapperProps } from "components/InputWrapper";
import { SelectionType } from "components/SelectionIndicator";

import styles from "./Question.module.scss";

type Props = {
  options: { option: ReactNode; value: string; leftSide?: ReactNode; rightSide?: ReactNode }[];
  value: string | string[];
  onClick: (value: string) => void;
  answerType?: SelectionType;
  wrapperClassName?: string;
  disabled?: boolean;
  dataKeyPrefix?: string;
} & InputWrapperProps;

export const Question = ({
  options,
  value,
  onClick,
  className,
  wrapperClassName,
  answerType,
  disabled,
  dataKeyPrefix,
  ...rest
}: Props) => {
  const renderAnswer = (answer: Props["options"][number], index: number) => {
    const selected = value.includes(answer.value);
    const onAnswerClick = () => {
      onClick(answer.value);
    };
    if (answerType) {
      return (
        <SelectionOption
          dataKey={dataKeyPrefix ? `${dataKeyPrefix}__${answer.value}` : answer.value}
          key={index}
          type={answerType}
          checked={selected}
          option={answer.option}
          onClick={onAnswerClick}
          disabled={disabled}
          rightSide={answer.rightSide}
        />
      );
    } else {
      return (
        <AnswerOption
          dataKey={dataKeyPrefix ? `${dataKeyPrefix}__${answer.value}` : answer.value}
          key={index}
          option={answer.option}
          onClick={onAnswerClick}
          disabled={disabled}
          rightSide={answer.rightSide}
        />
      );
    }
  };

  return (
    <InputWrapper className={cn(styles.inputWrapper, wrapperClassName)} {...rest}>
      <Space direction='vertical' size={6} className={styles.answersSpace}>
        {options?.map((props, idx) => renderAnswer(props, idx))}
      </Space>
    </InputWrapper>
  );
};
