import React, { useState } from "react";

import { Space } from "antd";

import { BackButton } from "components/BackButton";
import { SubmitButton } from "components/SubmitButton";
import { SummarySection } from "components/SummarySection";
import { HOME_PATH } from "constants/pages";
import { useCountriesContext } from "contexts/countriesCtx";
import { useFormikActions, useKycData, useMutateKyc, usePostcodeSample, useStepsProgress } from "hooks";
import { KycInd } from "types/kyc";
import { getCountryByCode } from "utils/formData";

import styles from "./CarriedOverConfirmation.module.scss";

export const CarriedOverConfirmation = () => {
  const { data } = useKycData();
  if (!data) return null;

  const currentData = data.last as KycInd;
  const { countriesOptions = [] } = useCountriesContext();
  const updateKyc = useMutateKyc();
  const { goToNextScreen, goToPreviousScreen, setScreen } = useStepsProgress();

  const poaData = [
    { label: "country", value: getCountryByCode(currentData.addressCountry, countriesOptions) },
    { label: "address", value: currentData.addressLine1 },
    { label: "city", value: currentData.city },
    { label: "state", value: currentData.state },
    { label: "poscode/zip", value: currentData.postcode },
    {
      label: "place of birth",
      value: getCountryByCode(currentData.placeOfBirth, countriesOptions),
    },
    {
      label: "nationality",
      value: getCountryByCode(currentData.nationality, countriesOptions),
    },
    { label: "another nationality", value: currentData.secondaryNationalities?.[0].has || "No" },
  ];

  function handleConfirmation(): void {
    updateKyc.mutateAsync({ currentStep: 7, poaCarriedOverDecision: "agree" }).then(() => {
      goToNextScreen();
    });
  }

  function handleDecline(): void {
    updateKyc.mutateAsync({ currentStep: 3, poaCarriedOverDecision: "disagree" }).then(() => {
      setScreen(3);
    });
  }

  return (
    <div className={styles.addressDetails}>
      <h2 data-cy='address-details-title'>Confirm your address details</h2>
      <p>Please confirm if the address we have on file is accurate and up-to-date</p>

      <SummarySection header='' headerButton={""} data={poaData} spaceSize={20} />

      <Space align='center' className={styles.actions} direction='vertical' size={0}>
        <p>Is this address still correct, or do you need to update it?</p>
        <SubmitButton onClick={handleConfirmation} className={styles.carriedOverAccept}>
          Yes, this address is correct
        </SubmitButton>
        <BackButton onClick={handleDecline} className={styles.carriedOverDecline}>
          No, I need to update it
        </BackButton>
        <BackButton
          onClick={() => {
            goToPreviousScreen(() => {
              updateKyc.mutateAsync({ currentStep: 2 });
            });
          }}
        />
      </Space>
    </div>
  );
};
