import React from "react";

import styles from "./Tier4AccountBtnTooltip.module.scss";

type Tier4AccountBtnTooltipProps = {
  onClick?: () => void;
};

export const Tier4AccountBtnTooltip = ({ onClick }: Tier4AccountBtnTooltipProps) => {
  return (
    <div className={styles.tier4AccountBtnTooltip}>
      <p>Requires High Net Worth (HNW) - If your net worth is now over $1M, update it in Tier 1 to proceed.</p>
      {!!onClick && <a onClick={onClick}>Learn more</a>}
    </div>
  );
};
